<template>
  <div>
    <div class="py-16">
      <h1 class="mb-4 text-center font-merri text-4xl">About Us</h1>
      <div class="relative sm:-mt-24">
        <div class="text-teal-200">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              class="fill-current"
              fill-opacity="1"
              d="M0,64L48,85.3C96,107,192,149,288,154.7C384,160,480,128,576,122.7C672,117,768,139,864,128C960,117,1056,75,1152,74.7C1248,75,1344,117,1392,138.7L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
            ></path>
          </svg>
        </div>
        <div :class="`bg-teal-200`">
          <div class="container mx-auto sm:-mt-24">
            <div class="mx-auto flex w-full flex-col sm:w-2/3">
              <div
                v-for="(item, index) in choosePoints"
                class="flex w-full items-start p-8 sm:w-2/3"
                :key="`${item}-${index}`"
                :class="{ 'self-end': index % 2 }"
              >
                <div class="font-merri text-5xl font-bold text-teal-400">
                  {{ index + 1 }}.
                </div>
                <div class="ml-4 mt-4 flex flex-col">
                  <div class="font-semibold">{{ item.heading }}</div>
                  <div>{{ item.description }}</div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="mx-auto mt-16 mt-8 w-1/2 w-full rounded-lg p-8 pb-0 pb-8 text-center sm:justify-center sm:pb-0"
          >
            <div class="text-center text-lg font-semibold sm:text-xl">
              Our healthy food starts from less than £1 a day incl. free
              delivery.
            </div>
            <div class="mb-8 mt-4 text-center text-lg font-semibold sm:text-xl">
              Dip your paws in the water...
            </div>
            <action-ctas />
          </div>
        </div>
      </div>

      <div :class="`text-teal-200`">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            class="default-transition fill-current"
            fill-opacity="1"
            d="M0,0L48,42.7C96,85,192,171,288,181.3C384,192,480,128,576,101.3C672,75,768,85,864,85.3C960,85,1056,75,1152,64C1248,53,1344,43,1392,37.3L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
          ></path>
        </svg>
      </div>

      <div class="container mx-auto sm:-mt-16">
        <div class="mx-auto flex w-full p-4 sm:w-1/2 sm:p-0">
          <img
            alt="The Cool Cat Club Parky"
            src="images/parky-full.webp"
            width="320px"
            class="right-0 -mt-16 hidden sm:absolute sm:mr-8 sm:flex lg:mr-16"
          />
          <div class="z-10">
            <h2 class="mb-8 text-center font-merri text-xl">Our Story</h2>
            <p>
              Once upon a time we found a thin and dishevelled looking tuxedo
              cat in a city centre car park. He’d wait by our car every day. One
              day we got him cleaned and checked over and Parky from the car
              park moved in with us. (or perhaps he chose us).
            </p>

            <p>
              At first he was fine. We bought cat food from the supermarket as
              well as branded food but he wasn’t happy. He wasn’t digesting it
              well and his coat looked dull. After lots of trips to the vets,
              including multiple blood and stool tests, our vet suggested
              avoiding commercial food from leading brands due to the low meat
              content, sugar and nasties added.
            </p>

            <p>
              We decided to take a big deep dive into how cat food is produced
              and what goes into it. We felt conned by these household brands.
              Their food hadn’t really moved on since the 1970s with cheap
              ingredients, sugar and nasties being the preferred choice!
            </p>

            <p>
              This had to change. To cut an incredibly long story short, we
              spent 18 months, working with a leading vet and nutritionist, to
              develop food in line with how cats naturally eat. Food that would
              reduce the chances of health problems and could be offered at an
              achievable price point to many, despite containing premium
              ingredients.
            </p>

            <p>
              Today we are able to offer proper, healthy nutrition and a viable
              alternative to nonsense food.
            </p>

            <p>
              As part of our overall offering we thought hard about what we
              would want and the kind of company we wished existed; If people
              want to adjust or cancel, make it easy; Give food to less
              fortunate cats; use recyclable products wherever possible and make
              it all accessible.
            </p>

            <p>
              We look forward to the opportunity to serve you and help the lives
              of more cool cats!
            </p>
            <p>Paul</p>
            <div class="text-sm italic text-gray-600">
              You can get in touch personally anytime at
              <a class="underline" href="mailto:paul@thecoolcatclub.com"
                >paul@thecoolcatclub.com</a
              >
              It would be great to hear from you or your cat.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionCtas from '@/components/ActionCtas';
export default {
  metaInfo: {
    title: 'Healthiest Dry & Wet Cat Food from The Cool Cat Club',
    meta: [
      {
        name: 'description',
        content:
          'Create a healthy monthly diet for your cat with the healthiest Grain Free & Real Meat Cat & Kitten Food Subscription service from The Cool Cat Club.'
      }
    ]
  },
  components: {
    ActionCtas
  },
  data() {
    return {
      choosePoints: [
        {
          heading: 'We’re a small UK company',
          description: 'We care and it shows in everything we do.'
        },
        {
          heading: 'We’re cat people',
          description:
            'Slightly obsessed, potentially bonkers, yet we thoroughly understand cats. We’re excited to be creating new food that directly improves the lives of our furry friends.'
        },
        {
          heading: 'Food most natural to cats',
          description:
            'Our food is all high meat content, grain free and without sugar. As it should be. We don’t hide our ingredients or food images, we’re proud to put them upfront and centre. '
        },
        {
          heading: 'Making your life simpler',
          description:
            "We offer the convenience of buying high quality wet and dry food from one place, never running out, reliable pricing and stock levels... because life's stressful enough."
        },
        {
          heading: 'Accessibly priced',
          description:
            'We want to make our healthy food accessible to as many owners as possible in order to help more cats. By supplying direct to customers and not hiking the price (like others!) we’re able to do this.'
        },
        {
          heading: 'All cats matter',
          description:
            'For every month a cool cat is subscribed, we give 5 pouches to a cat in a cat shelter. All cats are important to us, whatever their circumstances.'
        },
        {
          heading: 'Puurrrsuit of excellence',
          description:
            'We’re very humbled cat owners seem to like what they receive being a member with us and rating us Excellent on Trustpilot. We’re here for them, this matters.'
        }
      ]
    };
  }
};
</script>
